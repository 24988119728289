import Icon from "@ant-design/icons"

export const Successfully = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.5 0C37.4636 0 42.1609 1.13082 46.358 3.14781C44.64 4.50697 43.0471 5.81176 41.5629 7.06762C38.7358 6.04009 35.6859 5.48012 32.5054 5.48012C25.1823 5.48012 18.5496 8.44852 13.7545 13.2491C8.95396 18.0496 5.98556 24.6769 5.98556 32C5.98556 39.3231 8.95396 45.9504 13.7545 50.7509C18.555 55.5515 25.1823 58.5199 32.5054 58.5199C39.8286 58.5199 46.4613 55.5515 51.2564 50.7509C56.0569 45.9504 59.0253 39.3231 59.0253 32C59.0253 30.2603 58.8568 28.5532 58.536 26.9059C59.9115 25.1118 61.3196 23.3231 62.7603 21.5508C63.8911 24.8236 64.5054 28.3411 64.5054 32C64.5054 40.8345 60.9227 48.8372 55.1327 54.6273C49.3427 60.4173 41.34 64 32.5054 64C23.6709 64 15.6682 60.4173 9.87819 54.6273C4.08274 48.8372 0.5 40.8345 0.5 32C0.5 23.1655 4.08274 15.1628 9.87275 9.37275C15.6628 3.58274 23.6655 0 32.5 0ZM17.5928 26.7428L25.3998 26.6395L25.9815 26.7917C27.5581 27.6996 29.0423 28.738 30.4286 29.9123C31.429 30.7604 32.3858 31.6847 33.2938 32.685C36.0936 28.178 39.0783 24.0408 42.2316 20.2351C45.6838 16.0652 49.3481 12.2813 53.1973 8.82909L53.9584 8.53551H62.4776L60.7596 10.4438C55.4806 16.3099 50.691 22.3717 46.3634 28.6239C42.0359 34.8814 38.165 41.3401 34.7236 47.9891L33.6526 50.055L32.6685 47.9511C30.8527 44.053 28.6781 40.4757 26.0848 37.279C23.4915 34.0822 20.4742 31.2443 16.9567 28.8304L17.5928 26.7428Z" fill="#00AB6F" />
  </svg>
)

const Dashboard = () => (
  <svg width="18" height="18" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M5.35455 3C4.08558 3 3 4.05876 3 5.33333V6.90385C3 8.26558 4.10199 9.21342 5.30531 9.29827C5.3217 9.29942 5.33812 9.3 5.35455 9.3H6.94545C8.21442 9.3 9.3 8.24124 9.3 6.96667V5.33333C9.3 4.09468 8.31348 3 7.00909 3H5.35455ZM13.0545 3C11.7856 3 10.7 4.05876 10.7 5.33333V6.96667C10.7 8.24124 11.7856 9.3 13.0545 9.3H14.6455C15.9144 9.3 17 8.24124 17 6.96667V5.33333C17 4.09468 16.0135 3 14.7091 3H13.0545ZM5.35455 10.7C4.08558 10.7 3 11.7588 3 13.0333V14.6667C3 15.9817 4.11981 16.9147 5.30531 16.9983C5.3217 16.9994 5.33812 17 5.35455 17H6.94545C8.21442 17 9.3 15.9412 9.3 14.6667V13.0333C9.3 11.7947 8.31348 10.7 7.00909 10.7H5.35455ZM13.0545 10.7C11.7856 10.7 10.7 11.7588 10.7 13.0333V14.6667C10.7 15.9412 11.7856 17 13.0545 17H14.6455C15.9144 17 17 15.9412 17 14.6667V13.0333C17 11.7947 16.0135 10.7 14.7091 10.7H13.0545Z" fill="#72849A"/>
  </svg>
)
const UserManagement = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.67 9.12988C17.04 10.0599 18 11.3199 18 12.9999V15.9999H22V12.9999C22 10.8199 18.43 9.52988 15.67 9.12988Z" fill="#72849A" />
    <path d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C13.53 0 13.09 0.0999998 12.67 0.24C13.5 1.27 14 2.58 14 4C14 5.42 13.5 6.73 12.67 7.76C13.09 7.9 13.53 8 14 8Z" fill="#72849A" />
    <path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2Z" fill="#72849A" />
    <path d="M8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9ZM14 14H2V13.01C2.2 12.29 5.3 11 8 11C10.7 11 13.8 12.29 14 13V14Z" fill="#72849A" />
  </svg>

)
const AccountManagement = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.49984 1.66699C5.3165 1.66699 3.5415 3.44199 3.5415 5.62533C3.5415 7.76699 5.2165 9.50033 7.39984 9.57533C7.4665 9.56699 7.53317 9.56699 7.58317 9.57533C7.59984 9.57533 7.60817 9.57533 7.62484 9.57533C7.63317 9.57533 7.63317 9.57533 7.6415 9.57533C9.77484 9.50033 11.4498 7.76699 11.4582 5.62533C11.4582 3.44199 9.68317 1.66699 7.49984 1.66699Z" fill="#72849A"/>
  <path d="M11.7333 11.7914C9.4083 10.2414 5.61663 10.2414 3.27497 11.7914C2.21663 12.4997 1.6333 13.4581 1.6333 14.4831C1.6333 15.5081 2.21663 16.4581 3.26663 17.1581C4.4333 17.9414 5.96663 18.3331 7.49997 18.3331C9.0333 18.3331 10.5666 17.9414 11.7333 17.1581C12.7833 16.4497 13.3666 15.4997 13.3666 14.4664C13.3583 13.4414 12.7833 12.4914 11.7333 11.7914Z" fill="#72849A"/>
  <path d="M16.6583 6.11708C16.7916 7.73374 15.6416 9.15041 14.05 9.34208C14.0416 9.34208 14.0416 9.34208 14.0333 9.34208H14.0083C13.9583 9.34208 13.9083 9.34207 13.8666 9.35874C13.0583 9.40041 12.3166 9.14207 11.7583 8.66707C12.6166 7.90041 13.1083 6.75041 13.0083 5.50041C12.95 4.82541 12.7166 4.20874 12.3666 3.68374C12.6833 3.52541 13.05 3.42541 13.425 3.39208C15.0583 3.25041 16.5166 4.46708 16.6583 6.11708Z" fill="#72849A"/>
  <path d="M18.3249 13.8247C18.2582 14.633 17.7415 15.333 16.8749 15.808C16.0415 16.2663 14.9915 16.483 13.9499 16.458C14.5499 15.9163 14.8999 15.2413 14.9665 14.5247C15.0499 13.4913 14.5582 12.4997 13.5749 11.708C13.0165 11.2663 12.3665 10.9163 11.6582 10.658C13.4999 10.1247 15.8165 10.483 17.2415 11.633C18.0082 12.2497 18.3999 13.0247 18.3249 13.8247Z" fill="#72849A"/>
  </svg>

)
const InquiryManagement = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1667 0.666016H4.83335C2.53335 0.666016 0.666687 2.52435 0.666687 4.81602V9.79935V10.6327C0.666687 12.9244 2.53335 14.7827 4.83335 14.7827H6.08335C6.30835 14.7827 6.60835 14.9327 6.75002 15.116L8.00002 16.7744C8.55002 17.5077 9.45002 17.5077 10 16.7744L11.25 15.116C11.4084 14.9077 11.6584 14.7827 11.9167 14.7827H13.1667C15.4667 14.7827 17.3334 12.9244 17.3334 10.6327V4.81602C17.3334 2.52435 15.4667 0.666016 13.1667 0.666016ZM5.66669 8.99935C5.20002 8.99935 4.83335 8.62435 4.83335 8.16602C4.83335 7.70768 5.20835 7.33268 5.66669 7.33268C6.12502 7.33268 6.50002 7.70768 6.50002 8.16602C6.50002 8.62435 6.13335 8.99935 5.66669 8.99935ZM9.00002 8.99935C8.53335 8.99935 8.16669 8.62435 8.16669 8.16602C8.16669 7.70768 8.54169 7.33268 9.00002 7.33268C9.45835 7.33268 9.83335 7.70768 9.83335 8.16602C9.83335 8.62435 9.46669 8.99935 9.00002 8.99935ZM12.3334 8.99935C11.8667 8.99935 11.5 8.62435 11.5 8.16602C11.5 7.70768 11.875 7.33268 12.3334 7.33268C12.7917 7.33268 13.1667 7.70768 13.1667 8.16602C13.1667 8.62435 12.8 8.99935 12.3334 8.99935Z" fill="#72849A" />
  </svg>
)
const ContractManagement = () => (
  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M2 21V7C2 5.11438 2 4.17157 2.58579 3.58579C3.17157 3 4.11438 3 6 3H16C16.8317 3 17.4778 3 18.0037 3.02706C15.7519 3.2741 14 5.18245 14 7.5V11.0002L13.9012 20.9671L11 20L8 21L5 20L2 21ZM21 11L16 11V7.5C16 6.11929 17.1193 5 18.5 5C19.8807 5 21 6.11929 21 7.5V11ZM4 7C4 6.44772 4.44772 6 5 6H11C11.5523 6 12 6.44772 12 7C12 7.55228 11.5523 8 11 8H5C4.44772 8 4 7.55228 4 7ZM4 11C4 10.4477 4.44772 10 5 10H7C7.55228 10 8 10.4477 8 11C8 11.5523 7.55228 12 7 12H5C4.44772 12 4 11.5523 4 11ZM4 15C4 14.4477 4.44772 14 5 14H9C9.55228 14 10 14.4477 10 15C10 15.5523 9.55228 16 9 16H5C4.44772 16 4 15.5523 4 15Z" fill="#72849A"/>
  </svg>
)
const AttendanceManagement = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 22q-.825 0-1.413-.587Q3 20.825 3 20V6q0-.825.587-1.412Q4.175 4 5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588Q21 5.175 21 6v14q0 .825-.587 1.413Q19.825 22 19 22Zm0-2h14V10H5v10ZM5 8h14V6H5Zm0 0V6v2Zm7 6q-.425 0-.712-.288Q11 13.425 11 13t.288-.713Q11.575 12 12 12t.713.287Q13 12.575 13 13t-.287.712Q12.425 14 12 14Zm-4 0q-.425 0-.713-.288Q7 13.425 7 13t.287-.713Q7.575 12 8 12t.713.287Q9 12.575 9 13t-.287.712Q8.425 14 8 14Zm8 0q-.425 0-.712-.288Q15 13.425 15 13t.288-.713Q15.575 12 16 12t.712.287Q17 12.575 17 13t-.288.712Q16.425 14 16 14Zm-4 4q-.425 0-.712-.288Q11 17.425 11 17t.288-.712Q11.575 16 12 16t.713.288Q13 16.575 13 17t-.287.712Q12.425 18 12 18Zm-4 0q-.425 0-.713-.288Q7 17.425 7 17t.287-.712Q7.575 16 8 16t.713.288Q9 16.575 9 17t-.287.712Q8.425 18 8 18Zm8 0q-.425 0-.712-.288Q15 17.425 15 17t.288-.712Q15.575 16 16 16t.712.288Q17 16.575 17 17t-.288.712Q16.425 18 16 18Z" fill='white' /></svg>
)
const TaskManagement = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4917 0.166992H5.50832C2.47499 0.166992 0.666656 1.97533 0.666656 5.00866V11.992C0.666656 15.0253 2.47499 16.8337 5.50832 16.8337H12.4917C15.525 16.8337 17.3333 15.0253 17.3333 11.992V5.00866C17.3333 1.97533 15.525 0.166992 12.4917 0.166992ZM7.30832 10.917L5.43332 12.792C5.30832 12.917 5.14999 12.9753 4.99166 12.9753C4.83332 12.9753 4.66666 12.917 4.54999 12.792L3.92499 12.167C3.67499 11.9253 3.67499 11.5253 3.92499 11.2837C4.16666 11.042 4.55832 11.042 4.80832 11.2837L4.99166 11.467L6.42499 10.0337C6.66666 9.79199 7.05832 9.79199 7.30832 10.0337C7.54999 10.2753 7.54999 10.6753 7.30832 10.917ZM7.30832 5.08366L5.43332 6.95866C5.30832 7.08366 5.14999 7.14199 4.99166 7.14199C4.83332 7.14199 4.66666 7.08366 4.54999 6.95866L3.92499 6.33366C3.67499 6.09199 3.67499 5.69199 3.92499 5.45033C4.16666 5.20866 4.55832 5.20866 4.80832 5.45033L4.99166 5.63366L6.42499 4.20033C6.66666 3.95866 7.05832 3.95866 7.30832 4.20033C7.54999 4.44199 7.54999 4.84199 7.30832 5.08366ZM13.6333 12.3503H9.25832C8.91666 12.3503 8.63332 12.067 8.63332 11.7253C8.63332 11.3837 8.91666 11.1003 9.25832 11.1003H13.6333C13.9833 11.1003 14.2583 11.3837 14.2583 11.7253C14.2583 12.067 13.9833 12.3503 13.6333 12.3503ZM13.6333 6.51699H9.25832C8.91666 6.51699 8.63332 6.23366 8.63332 5.89199C8.63332 5.55033 8.91666 5.26699 9.25832 5.26699H13.6333C13.9833 5.26699 14.2583 5.55033 14.2583 5.89199C14.2583 6.23366 13.9833 6.51699 13.6333 6.51699Z" fill="#72849A" />
  </svg>

)
const CMS = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.0087 8.07938C14.3019 7.48 14.81 7.02938 15.4419 6.81188C15.7063 6.72062 15.9788 6.68562 16.25 6.68437V3.125C16.25 2.09125 15.4087 1.25 14.375 1.25H4.375C3.34125 1.25 2.5 2.09125 2.5 3.125V15.625C2.5 16.6587 3.34125 17.5 4.375 17.5H10.5931L10.5256 17.3038C10.2537 16.5106 10.3069 15.6631 10.6737 14.9137L14.0087 8.07938ZM5 5H12.5C12.8456 5 13.125 5.28 13.125 5.625C13.125 5.97 12.8456 6.25 12.5 6.25H5C4.65438 6.25 4.375 5.97 4.375 5.625C4.375 5.28 4.65438 5 5 5ZM5 7.5H11.875C12.2206 7.5 12.5 7.78 12.5 8.125C12.5 8.47 12.2206 8.75 11.875 8.75H5C4.65438 8.75 4.375 8.47 4.375 8.125C4.375 7.78 4.65438 7.5 5 7.5ZM9.375 13.75H5C4.65438 13.75 4.375 13.47 4.375 13.125C4.375 12.78 4.65438 12.5 5 12.5H9.375C9.72062 12.5 10 12.78 10 13.125C10 13.47 9.72062 13.75 9.375 13.75ZM5 11.25C4.65438 11.25 4.375 10.97 4.375 10.625C4.375 10.28 4.65438 10 5 10H10.625C10.9706 10 11.25 10.28 11.25 10.625C11.25 10.97 10.9706 11.25 10.625 11.25H5ZM18.5025 10.2719L15.1663 17.1088C14.9456 17.5594 14.5638 17.8969 14.0906 18.0594L12.9087 18.4656C12.8419 18.4888 12.7725 18.5 12.7056 18.5C12.4462 18.5 12.2038 18.3375 12.115 18.0781L11.7081 16.8962C11.545 16.4213 11.5769 15.9125 11.7969 15.4631L15.1331 8.6275C15.2787 8.3275 15.5337 8.1025 15.8494 7.99375C16.1644 7.885 16.5037 7.905 16.8038 8.0525L17.9275 8.60063C18.2275 8.74687 18.4525 9.00187 18.5613 9.3175C18.67 9.63313 18.6494 9.97187 18.5025 10.2719Z" fill="#72849A"/>
  </svg>
)
const ItemAndServices = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3143_14741)">
      <path d="M19 6H17C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.66 3 15 4.34 15 6H9C9 4.34 10.34 3 12 3ZM19 20H5V8H19V20ZM12 12C10.34 12 9 10.66 9 9H7C7 11.76 9.24 14 12 14C14.76 14 17 11.76 17 9H15C15 10.66 13.66 12 12 12Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3143_14741">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

)
const DeliveryManagement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4614_15931)">
      <path d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM19.5 9.5L21.46 12H17V9.5H19.5ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM8.22 15C7.67 14.39 6.89 14 6 14C5.11 14 4.33 14.39 3.78 15H3V6H15V15H8.22ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_4614_15931">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>


)
const InventoryManagement = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.8401 6.51784L10.4234 10.2345C10.1651 10.3845 9.84007 10.3845 9.5734 10.2345L3.15673 6.51784C2.6984 6.25117 2.58173 5.62617 2.93173 5.23451C3.1734 4.95951 3.4484 4.73451 3.74007 4.57617L8.25673 2.07617C9.2234 1.53451 10.7901 1.53451 11.7567 2.07617L16.2734 4.57617C16.5651 4.73451 16.8401 4.96784 17.0817 5.23451C17.4151 5.62617 17.2984 6.25117 16.8401 6.51784ZM9.52507 11.7828V17.4662C9.52507 18.0995 8.8834 18.5162 8.31673 18.2412C6.60006 17.3995 3.7084 15.8245 3.7084 15.8245C2.69173 15.2495 1.8584 13.7995 1.8584 12.6078V8.30784C1.8584 7.64951 2.55007 7.23284 3.11673 7.55784L9.1084 11.0328C9.3584 11.1912 9.52507 11.4745 9.52507 11.7828ZM10.4751 11.7828V17.4662C10.4751 18.0995 11.1167 18.5162 11.6834 18.2412C13.4001 17.3995 16.2917 15.8245 16.2917 15.8245C17.3084 15.2495 18.1417 13.7995 18.1417 12.6078V8.30784C18.1417 7.64951 17.4501 7.23284 16.8834 7.55784L10.8917 11.0328C10.6417 11.1912 10.4751 11.4745 10.4751 11.7828Z" fill="#72849A"/>
  </svg>
)
const FinanceManagement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM17 14H3V6H17V14ZM10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7ZM23 7V18C23 19.1 22.1 20 21 20H4C4 19 4 19.1 4 18H21V7C22.1 7 22 7 23 7Z" fill="#72849A" />
  </svg>

)
const Reports = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3143_14712)">
      <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#72849A" />
    </g>
    <defs>
      <clipPath id="clip0_3143_14712">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

)
const Message = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1667 0.666016H4.83335C2.53335 0.666016 0.666687 2.52435 0.666687 4.81602V9.79935V10.6327C0.666687 12.9244 2.53335 14.7827 4.83335 14.7827H6.08335C6.30835 14.7827 6.60835 14.9327 6.75002 15.116L8.00002 16.7744C8.55002 17.5077 9.45002 17.5077 10 16.7744L11.25 15.116C11.4084 14.9077 11.6584 14.7827 11.9167 14.7827H13.1667C15.4667 14.7827 17.3334 12.9244 17.3334 10.6327V4.81602C17.3334 2.52435 15.4667 0.666016 13.1667 0.666016ZM5.66669 8.99935C5.20002 8.99935 4.83335 8.62435 4.83335 8.16602C4.83335 7.70768 5.20835 7.33268 5.66669 7.33268C6.12502 7.33268 6.50002 7.70768 6.50002 8.16602C6.50002 8.62435 6.13335 8.99935 5.66669 8.99935ZM9.00002 8.99935C8.53335 8.99935 8.16669 8.62435 8.16669 8.16602C8.16669 7.70768 8.54169 7.33268 9.00002 7.33268C9.45835 7.33268 9.83335 7.70768 9.83335 8.16602C9.83335 8.62435 9.46669 8.99935 9.00002 8.99935ZM12.3334 8.99935C11.8667 8.99935 11.5 8.62435 11.5 8.16602C11.5 7.70768 11.875 7.33268 12.3334 7.33268C12.7917 7.33268 13.1667 7.70768 13.1667 8.16602C13.1667 8.62435 12.8 8.99935 12.3334 8.99935Z" fill="#72849A" />
  </svg>

)
const Master = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.75 7.6819C15.2417 7.6819 14.625 6.61523 15.375 5.3069C15.8083 4.54856 15.55 3.5819 14.7917 3.14856L13.35 2.32356C12.6917 1.9319 11.8417 2.16523 11.45 2.82356L11.3583 2.9819C10.6083 4.29023 9.375 4.29023 8.61667 2.9819L8.525 2.82356C8.15 2.16523 7.3 1.9319 6.64167 2.32356L5.2 3.14856C4.44167 3.5819 4.18333 4.5569 4.61667 5.31523C5.375 6.61523 4.75833 7.6819 3.25 7.6819C2.38333 7.6819 1.66667 8.39023 1.66667 9.26523V10.7319C1.66667 11.5986 2.375 12.3152 3.25 12.3152C4.75833 12.3152 5.375 13.3819 4.61667 14.6902C4.18333 15.4486 4.44167 16.4152 5.2 16.8486L6.64167 17.6736C7.3 18.0652 8.15 17.8319 8.54167 17.1736L8.63333 17.0152C9.38333 15.7069 10.6167 15.7069 11.375 17.0152L11.4667 17.1736C11.8583 17.8319 12.7083 18.0652 13.3667 17.6736L14.8083 16.8486C15.5667 16.4152 15.825 15.4402 15.3917 14.6902C14.6333 13.3819 15.25 12.3152 16.7583 12.3152C17.625 12.3152 18.3417 11.6069 18.3417 10.7319V9.26523C18.3333 8.39856 17.625 7.6819 16.75 7.6819ZM10 12.7069C8.50834 12.7069 7.29167 11.4902 7.29167 9.99856C7.29167 8.5069 8.50834 7.29023 10 7.29023C11.4917 7.29023 12.7083 8.5069 12.7083 9.99856C12.7083 11.4902 11.4917 12.7069 10 12.7069Z" fill="#72849A"/>
  </svg>

)

export const DashboardIcon = () => { return <Icon component={Dashboard} /> }
export const UserManagementIcon = () => { return <Icon component={UserManagement} /> }
export const AccountManagementIcon = () => { return <Icon component={AccountManagement} /> }
export const InquiryManagementIcon = () => { return <Icon component={InquiryManagement} /> }
export const ContractManagementIcon = () => { return <Icon component={ContractManagement} /> }
export const AttendanceManagementIcon = () => { return <Icon component={AttendanceManagement} /> }
export const TaskManagementIcon = () => { return <Icon component={TaskManagement} /> }
export const CMSIcon = () => { return <Icon component={CMS} /> }
export const MessageIcon = () => { return <Icon component={Message} /> }
export const ItemAndServicesIcon = () => { return <Icon component={ItemAndServices} /> }
export const DeliveryManagementIcon = () => { return <Icon component={DeliveryManagement} /> }
export const InventoryManagementIcon = () => { return <Icon component={InventoryManagement} /> }
export const FinanceManagementIcon = () => { return <Icon component={FinanceManagement} /> }
export const ReportIcon = () => { return <Icon component={Reports} /> }
export const MasterIcon = () => { return <Icon component={Master} /> }

